export {};

// Set polyfill
declare global {
	interface Set<T> {
		transform<U>(callback: (value: T, index: number) => U): Set<U>;
		map<U>(callback: (value: T, index: number) => U): U[];
		find(callback: (value: T, index: number) => boolean): T | undefined;

		readonly first: T;
	}
}

Set.prototype.transform = function <T, U>(this: Set<T>, callback: (value: T, index: number) => U) {
	return new Set<U>(Array.from(this, callback));
};

Set.prototype.map = function <T, U>(this: Set<T>, callback: (value: T, index: number) => U) {
	return Array.from(this, callback);
};

Set.prototype.find = function <T>(this: Set<T>, callback: (value: T, index: number) => boolean) {
	let index = 0;
	for (const value of this) {
		if (callback(value, index++)) {
			return value;
		}
	}
	return undefined;
};

Object.defineProperty(Set.prototype, "first", {
	get: function <T>(this: Set<T>) {
		let [first] = this;
		return first;
	}
});

// Map polyfill
declare global {
	interface Map<K, V> {
		transform<U>(callback: (value: V, key: K) => U): Map<K, U>;
		map<U>(callback: (value: V, key: K) => U): U[];

		readonly firstEntry: [K, V];
		readonly firstKey: K;
		readonly firstValue: V;
	}
}

Map.prototype.transform = function <K, V, U>(this: Map<K, V>, callback: (value: V, key: K) => U) {
	return new Map<K, U>(Array.from(this, ([k, v]) => [k, callback(v, k)]));
};

Map.prototype.map = function <K, V, U>(this: Map<K, V>, callback: (value: V, key: K) => U) {
	return Array.from(this, ([k, v]) => callback(v, k));
};

Object.defineProperty(Map.prototype, "firstEntry", {
	get: function <K, V>(this: Map<K, V>) {
		let [first] = this.entries();
		return first;
	}
});
Object.defineProperty(Map.prototype, "firstKey", {
	get: function <K, V>(this: Map<K, V>) {
		let [first] = this.keys();
		return first;
	}
});
Object.defineProperty(Map.prototype, "firstValue", {
	get: function <K, V>(this: Map<K, V>) {
		let [first] = this.values();
		return first;
	}
});

// Object polyfill
declare global {
	interface ObjectConstructor {
		map<U, T extends { [K: string]: any }>(object: T, callback: (value: T[keyof T], key: string) => U): U[];
		map<U, T extends Object>(object: T, callback: (value: T[keyof T], key: keyof T) => U): U[];

		mapKey<U, T extends Object>(object: T, callback: (key: keyof T) => U): U[];

		transform<U, T extends { [K: string]: any }>(
			object: T,
			callback: (value: T[keyof T], key: string) => U
		): { [K in keyof T]: U };
	}
}

Object.map = function <U, T extends Object>(object: T, callback: (value: T[keyof T], key: keyof T) => U) {
	return Object.entries(object).map(([key, value]) => callback(value, key as keyof T));
} as any;

Object.mapKey = function <U, T extends Object>(object: T, callback: (key: keyof T) => U) {
	return Object.keys(object).map((key) => callback(key as keyof T));
};

Object.transform = function <U, T extends Object>(object: T, callback: (value: T[keyof T], key: keyof T) => U) {
	return Object.fromEntries(Object.entries(object).map(([key, value]) => [key, callback(value, key as keyof T)]));
} as any;

// String polyfill
declare global {
	interface String {
		reduceChars<T>(callback: (previousValue: T, currentValue: string) => T, initialValue: T): T;
		reverse(): string;
	}
}

String.prototype.reduceChars = function <U>(
	this: string,
	callback: (previousValue: U, currentValue: string) => U,
	initialValue: U
): U {
	let accumulator = initialValue;
	for (let i = 0; i < this.length; i++) {
		accumulator = callback.call(undefined, accumulator, this[i]);
	}
	return accumulator;
};

String.prototype.reverse = function (this: string) {
	return this.split("").reverse().join("");
};

// Array polyfill

declare global {
	interface Array<T> {
		readonly first: T;
		readonly last: T;

		orDefault<T>(index: number, value: () => T): T;

		take<S extends T>(
			predicate: (this: void, value: T, index: number, obj: T[]) => value is S,
			thisArg?: any
		): S | undefined;
		take(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): T | undefined;
	}
}

Object.defineProperty(Array.prototype, "first", {
	get: function <T>(this: Array<T>) {
		return this.length > 0 ? this[0] : undefined;
	}
});
Object.defineProperty(Array.prototype, "last", {
	get: function <T>(this: Array<T>) {
		return this.length > 0 ? this[this.length - 1] : undefined;
	}
});

Array.prototype.take = function <T>(
	predicate: (value: T, index: number, obj: T[]) => unknown,
	thisArg?: any
): T | undefined {
	let index = this.findIndex(predicate, thisArg);
	if (index < 0) {
		return undefined;
	}

	return this.splice(index, 1)[0];
};

Array.prototype.orDefault = function <T>(this: Array<T>, index: number, value: () => T): T {
	let current = this[index];
	if (current === undefined) {
		current = value();
		this[index] = current;
	}
	return this[index];
};
