import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [];

export const dictionary = {
		"/": [13],
		"/(authenticated)/admin": [14,[2,3]],
		"/(authenticated)/admin/[slug]": [15,[2,3,4]],
		"/(authenticated)/admin/[slug]/[id]": [16,[2,3,4,5]],
		"/(authenticated)/dashboard": [17,[2]],
		"/(authenticated)/demo": [18,[2,6]],
		"/(authenticated)/demo/[org]/[id]": [19,[2,6]],
		"/(authenticated)/experimental": [20,[2,7]],
		"/(authenticated)/experimental/hewsync": [21,[2,7,8]],
		"/(authenticated)/organizations": [22,[2,9]],
		"/(authenticated)/organizations/[organization]": [23,[2,9,10]],
		"/(authenticated)/organizations/[organization]/project/[id]": [24,[2,9,10]],
		"/(authenticated)/organizations/[organization]/project/[id]/[...page]": [25,[2,9,10]],
		"/(authenticated)/permissions": [26,[2,11]],
		"/(authenticated)/projects": [27,[2,12]],
		"/(authenticated)/projects/[id]": [28,[2,12]],
		"/(authenticated)/users": [29,[2]],
		"/(authenticated)/users/[id]": [30,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';